import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "../src/assets/css/global.css";
import { createI18n } from "vue-i18n";
import { languages, defaultLocale } from "./assets/i18n/index.js";
import ContentLoader from "./components/CustomComponents/ContentLoader.vue";
import LoadMore from "./components/CustomComponents/LoadMore.vue";
import AxiosPlugin from "./axios-plugin.js";
import Toaster from "@meforma/vue-toaster";
import moment from "moment";
import VOtpInput from "vue3-otp-input";
import VueSocialSharing from 'vue-social-sharing';





//import translations
const messages = Object.assign(languages);

// configure i18n
const i18n = createI18n({
  locale: defaultLocale,
  messages,
});
document.documentElement.lang = defaultLocale;

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labsComponents from "vuetify/labs/components";

import { dateMixin } from "./dateMixin.js";

import mitt from "mitt";
const emitter = mitt();

const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
});

const app = createApp(App)
  .use(store)
  .use(vuetify)
  .use(router)
  .use(AxiosPlugin)
  .use(Toaster)
  .use(moment)
  .use(VueSocialSharing)
  .use(i18n);

app.config.globalProperties.emitter = emitter;
app.component("content-loader", ContentLoader);
app.component("load-more", LoadMore);
app.mixin(dateMixin);
app.component("v-otp-input", VOtpInput);
app.mount("#app");
