<template>
  <div
    class="footer w-100"
    v-bind:style="sel_lang == 'ar' ? 'direction:rtl' : ''"
  >
    <div class="container-fluid" style="width: 90%; margin: 0px auto">
      <!-- {{footer_links.name}} -->
      <div class="row customLinks">
        <v-col cols="12" md="4" lg="4" sm="12" class="pr-10">
          <img
            v-if="footer_details.mall_logo != ''"
            :src="envImagePath + footer_details.mall_logo"
            style="width: 100%"
            alt="Gulf Mall"
          />
          <img
            v-else
            src="../assets/images/Gulf_logo.png"
            style="width: 100%"
            alt="Gulf Mall"
          />
          <p>{{ footer_details.footer_description }}</p>
          <p>{{ $t("reach_us") }}</p>
          <div class="socialIcons">
            <a href="https://www.facebook.com/gulfmallqatar" target="_blank"
              ><img src="../assets/images/facebook.png" alt="Facebook"
            /></a>
            <a href="https://www.instagram.com/gulfmallqatar/" target="_blank"
              ><img src="../assets/images/instagram.png" alt="Instagram"
            /></a>
            <a
              href="https://twitter.com/i/flow/login?redirect_after_login=%2Fgulfmallqatar%2F"
              target="_blank"
              ><img src="../assets/images/twitter.png" alt="Twitter"
            /></a>
          </div>
        </v-col>
        <v-col cols="6" md="2" lg="2" sm="3"
          v-for="(link, k) in footer_links.filter(
            (v) => v.name == 'Shopping' || v.name == 'تسوق'
          )"
          :key="k"
        >
          <p>{{ link.name }}</p>
          <ul>
            <li
              v-for="(submenu, i) in link.sub_categories"
              :key="i"
              style="cursor: pointer"
              class="footer-menu"
            >
              <span
                @click="goToCategory(submenu.slug, link.name, submenu.title)"
                >{{ submenu.name }}</span
              >
            </li>
          </ul>
        </v-col>
        <v-col cols="6" md="2" lg="2" sm="3">
          <div
            v-for="(link, k) in footer_links.filter(
              (v) =>
                v.name == 'Dining' ||
                v.name == 'Experience' ||
                v.name == 'تناول الطعام' ||
                v.name == 'تجربة'
            )"
            :key="k"
          >
            <p>{{ link.name }}</p>
            <ul>
              <li
                v-for="(submenu, i) in link.sub_categories"
                :key="i"
                style="cursor: pointer"
                class="footer-menu"
              >
                <span
                  @click="goToCategory(submenu.slug, link.name, submenu.title)"
                  >{{ submenu.name }}</span
                >
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="6" md="2" lg="2" sm="3">
          <div
            v-for="(link, k) in footer_links.filter(
              (v) =>
                v.name == 'Entertainment' ||
                v.name == 'More' ||
                v.name == 'ترفيه' ||
                v.name == 'أكثر'
            )"
            :key="k"
          >
            <p>{{ link.name }}</p>
            <ul>
              <li
                v-for="(submenu, i) in link.sub_categories"
                :key="i"
                style="cursor: pointer"
                class="footer-menu"
              >
                <span
                  @click="goToCategory(submenu.slug, link.name, submenu.title)"
                  >{{ submenu.name }}</span
                >
              </li>
            </ul>
          </div>
          <v-col cols="6" md="12" lg="12" sm="12" class="p-0">
            <div>
              <p>{{ $t("more") }}</p>
              <ul>
                <li
                  v-for="(link, k) in page_builder_links"
                  :key="k"
                  style="cursor: pointer"
                  class="footer-menu"
                  @click="goToPagebuilder(link.route_to)"
                >
                  {{ link.name }}
                </li>
              </ul>
            </div>
          </v-col>
        </v-col>
        <v-col cols="12" md="2" lg="2" sm="3">
          <div class="row">
            <v-col cols="12" md="12" lg="12" sm="12">
              <p>{{ $t("our_newsletter") }}</p>
              <v-form ref="form" v-model="valid">
                <v-row class="mx-auto mt-2">
                  <v-col cols="12" sm="12" md="12" class="p-0">
                    <v-tooltip text="Your Name" location="bottom">
                      <template v-slot:activator="{ props }">
                        <v-text-field
                          v-on="on"
                          v-model="subscribe.name"
                          :rules="fieldRules"
                          v-bind:label="$t('name')"
                          v-bind="props"
                          required
                          style="margin-bottom: 10px"
                          class="rtl"
                          variant="outlined"
                          density="compact"
                          hide-details=""
                        ></v-text-field>
                      </template>
                    </v-tooltip>
                    <v-tooltip text="Email" location="bottom">
                      <template v-slot:activator="{ props }">
                        <v-text-field
                          v-on="on"
                          v-model="subscribe.email"
                          :rules="emailRules"
                          v-bind:label="$t('email_address')"
                          v-bind="props"
                          required
                          style="margin-bottom: 10px"
                          class="rtl"
                          variant="outlined"
                          density="compact"
                          hide-details=""
                        ></v-text-field>
                      </template>
                    </v-tooltip>

                    <v-btn
                      @click="submit"
                      :disabled="!valid || btnloading"
                      size="small"
                      class="mr-2"
                      color="primary"
                      style="width: 100%"
                    >
                      {{ $t("subscribe_now") }}
                      <v-progress-circular
                        v-if="isBtnLoading"
                        indeterminate
                        width="1"
                        color="cancel"
                        size="x-small"
                        class="ml-2"
                      ></v-progress-circular>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </div>
          <div class="row pt-10">
            <p>{{ $t("download_the_app") }}</p>
            <div class="appStore">
              <img
                src="../assets/images/app-store-badge-1.png"
                alt="App Store"
              />
              <img
                src="../assets/images/google-store-badge-1.png"
                alt="Play Store"
              />
            </div>
          </div>
        </v-col>
      </div>
    </div>
    <div class="copyright container-fluid">
      <div class="row">
        <p>{{ footer_details.footer_copyright }}</p>
      </div>
    </div>
    <v-btn
      @click="scrollToTop()"
      style="position: fixed; bottom: 0; right: 12px"
      class="ma-2"
      color="#ffbf00"
      icon="mdi-chevron-up"
    ></v-btn>
  </div>
</template>

<script>
export default {
  //props: ["footer_links", "page_builder_links"],
  data: () => ({
    envImagePath: process.env.VUE_APP_IMAGE_PATH,
    events: [],
    input: null,
    nonce: 0,
    activitylog: [],
    displayedItems: [],
    footer_links: [],
    page_builder_links: [],
    loader: false,
    currentIndex: 0,
    itemsPerPage: 5,
    sel_lang: "",
    footer_details: [],
    google_icon: {
      icon_name: "timeline",
      color: "google_icon_gradient",
      icon: "material-symbols-outlined",
    },
    valid: false,
    btnloading: false,
    logged_in_userid: 0,
    userdetails: [],
    subscribe: {
      name: "",
      email: "",
    },
  }),
  mounted() {
    this.fetchCategories();
    this.getfooterdetails();
  },
  computed: {
    emailRules() {
      return [
        (v) => !!v || this.$t("email_required"),
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("email_valid"),
      ];
    },
    // isDisabled: function () {
    //   return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
    //     this.subscribe.email
    //   );
    // },
    fieldRules() {
      return [(v) => !!v || this.$t("field_required")];
    },
  },
  methods: {
    fetchCategories() {
      const params = {};
      if (this.$route.params.display || sessionStorage.getItem("ispreview")) {
        params.display = this.$route.params.display;
      }
      this.initval = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL_ADMIN + "fetch-lang-categories", {
          params: params,
        })
        .then((res) => {
          this.initval = false;
          // this.$toast.success(this.array_data);
          this.footer_links = res.data.categories;
          this.page_builder_links = res.data.pages;
          console.log(this.pagebuilder_links);
        })
        .catch((err) => {
          this.$toast.error(this.array_data);
          console.log(" error" + err);
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        //this.isDisabled = true;
        this.btnloading = true;
        this.loader = true;
        // Form is valid, process
        this.$axios
          .post(
            process.env.VUE_APP_API_URL_ADMIN + "subscribe_user",
            this.subscribe
          )
          .then((res) => {
            this.btnloading = true;
            if (Array.isArray(res.data.message)) {
              this.array_data = res.data.message.toString();
            } else {
              this.array_data = res.data.message;
            }
            if (res.data.status == "S") {
              this.message = res.data.message;
              this.subscribe.name = "";
              this.subscribe.email = "";
              this.btnloading = false;
              this.$toast.success(res.data.message);
            } else if (res.data.status == "E") {
              this.$toast.error(this.array_data);
              this.btnloading = false;
              // this.isDisabled = false;
            } else {
              this.btnloading = false;
              this.$toast.error(this.array_data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.loader = false;
      } else {
        //alert("Form is Invalid");
      }
    },
    getfooterdetails() {
      this.sel_lang = localStorage.getItem("pref_lang");
      this.$axios
        .get(process.env.VUE_APP_API_URL_ADMIN + "fetch-lang-footer-details")
        .then((res) => {
          this.footer_details = res.data;

          // this.city.country_id = res.data.countries.id;
          this.loader = false;
        });
    },
    goToCategory(slug, parent, pagename) {
      //alert(pagename);
      this.$router.push({
        name: pagename,
        query: { slug: slug, parent_cat: parent },
      });
    },
    goToPagebuilder(slug) {
      this.$router.push({ name: "pagebuilder", query: { slug: slug } });
    },
    clear() {
      this.$refs.form.reset();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
#lateral .v-speed-dial,
#lateral .v-btn--floating {
  position: absolute;
}
#lateral .v-btn--floating {
  margin: 0 0 16px 16px;
}
.footer {
  background-color: #fff;
  color: #000;
  position: relative;
  border-top: 11px solid #eee;
}

.footerLogo img {
  width: 100%;
}

.customParagraph {
  text-align: center;
  padding: 2rem 0 2rem 0rem;
}

ul {
  list-style-type: none;
  padding-left: 0em;
}

.customLinks p {
  font-weight: 600;
  padding-top: 10px;
  font-size: 13px;
}

.customLinks {
  padding: 38px 0px;
  display: flex;
  align-items: first baseline;
}

.footerLogo {
  height: 90px;
  width: 90px;
  position: absolute;
  top: -45px;
  left: 50%;
  margin-left: -35px;
  background: #f8f8f8;
  border-radius: 50%;
  padding: 10px 10px;
  border: 3px solid #804409cf;
}

.appStore {
  display: flex;
}

.socialIcons img {
  margin-right: 10px;
}

.copyright {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 1rem 0 0rem 0;
}

.socialIcons img {
  width: 25px;
}

.appStore img {
  width: 60%;
}
.v-btn__content {
  font-size: 12px !important;
}
.footer-menu > span {
  font-size: 13px;
}
.footer-menu {
  font-size: 13px;
}
</style>
