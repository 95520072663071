<template>
  <div class="w-100" v-bind:style="sel_lang == 'ar' ? 'direction:rtl' : ''">
    <div>
      <TopNav></TopNav>
    </div>
    <!-- Nav Bar -->
    <div style="max-width: 100% !important; padding-left: 20px">
      <nav class="navbar navbar-expand-lg navbar-light bg-white pb-0">
        <a class="navbar-brand" href="#">
          <img v-if="mall_logo != ''" style="width: 200px" :src="envImagePath + mall_logo" />
          <img v-else style="width: 200px" src="../assets/images/Gulf_logo.png" />
          <!-- <img style="width: 208px" src="../assets/images/Gulf-Mall-High-Res.png" /> -->
        </a>
        <i
          class="fa fa-map-marker fa-lg mobile-locator"
          @click="goToStoreLocator()"
          aria-hidden="true"
        ></i>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse"
          id="navbarNavDropdown"
          style="justify-content: flex-start"
        >
          <ul
            class="navbar-nav d-flex"
            style="
              width: 100%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <li
              class="nav-item dropdown mr-3"
              v-for="(menu, i) in header_links"
              :key="i"
            >
              <a
                class="nav-link"
                v-bind:class="[
                  menu.sub_categories.length > 0 ? 'dropdown-toggle' : '',
                ]"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="goToCategory(menu.slug, menu.name, menu.title)"
              >
                <span class="menu-name">{{ menu.name }}</span>
              </a>

              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
                v-if="menu.sub_categories.length > 0"
              >
                <a
                  class="dropdown-item"
                  v-for="(submenu, k) in menu.sub_categories"
                  :key="k"
                  @click="closeMenu"
                >
                  <div
                    class="cursor-pointer"
                    @click="
                      goToCategory(submenu.slug, menu.name, submenu.title)
                    "
                  >
                    <span class="sub-menu-name">{{ submenu.name }}</span>
                  </div>
                </a>
              </div>
            </li>
            <!-- Other menu items -->
            <li class="nav-item mr-3">
              <a
                class="nav-link cursor-pointer"
                @click="goToServices()"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="menu-name">{{ $t("services") }}</span></a
              >
            </li>
            <li class="nav-item dropdown mr-3">
              <a
                class="nav-link"
                v-bind:class="[
                  page_builder_links.length > 0 ? 'dropdown-toggle' : '',
                ]"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="menu-name">{{ $t("more") }}</span>
              </a>

              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
                v-if="page_builder_links.length > 0"
              >
                <a
                  class="dropdown-item"
                  @click="closeMenu"
                  v-for="(more, m) in page_builder_links"
                  :key="m"
                  ><div class="cursor-pointer" @click="goTo(more.route_to)">
                    <span class="sub-menu-name">{{ more.name }}</span>
                  </div></a
                >
              </div>
            </li>

            <li
              class="hours-block mr-3"
              style="padding: 17.8px 0px; margin-right: 2px"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-clock-o"></i>&nbsp;<span class="menu-name">{{
                  $t("happening_now")
                }}</span>
              </a>

              <div
                class="dropdown-menu"
                style="min-width: 272px; margin-top: 10px"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <h4 class="f-14 dropdwn-heading">
                  <span class="sub-menu-name">{{ $t("upcoming_events") }}</span>
                </h4>
                <a
                  v-for="(event, e) in upcoming_events"
                  :key="e"
                  class="dropdown-item cursor-pointer"
                  @click="closeMenu"
                  ><div
                    @click="goToEventsDetails(event.slug)"
                    class="happening-title"
                  >
                    {{ event.title }}
                  </div>
                  <div
                    @click="goToEventsDetails(event.slug)"
                    style="
                      border-bottom: 1px solid #e5e5e5;
                      padding-bottom: 6px;
                    "
                  >
                    {{ event.start_date }} - {{ event.end_date }}
                  </div>
                </a>
              </div>
            </li>
            <li
              class="hours-block mr-3"
              style="padding: 9.8px 0px; margin-right: 2px"
            >
              <a
                v-bind:style="
                  mall_timings.default_type == 'image'
                    ? 'display: flex;align-items: center;'
                    : ''
                "
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-clock-o"></i>&nbsp;<span class="menu-name">{{
                  $t("today_hours")
                }}</span
                ><br />
                <span v-if="mall_timings.todays_timings == null"
                  ><span v-if="mall_timings.default_type != 'image'">{{
                    $t("holiday")
                  }}</span></span
                >
                <span v-else
                  >{{ mall_timings.todays_timings.from_time }}
                  {{ mall_timings.todays_timings.from_meridiem }}-{{
                    mall_timings.todays_timings.to_time
                  }}
                  {{ mall_timings.todays_timings.to_meridiem }}</span
                >
              </a>

              <div
                class="dropdown-menu"
                style="
                  min-width: 294px;
                  margin-top: 12px;
                  right: 3px;
                  width: 50%;
                "
                aria-labelledby="navbarDropdownMenuLink"
              >
                <h4
                  class="f-14 dropdwn-heading"
                  style="text-align: center; font-size: 19px"
                >
                  {{ $t("opening_hours") }}
                </h4>
                <div v-if="mall_timings.default_type != 'image'">
                  <a
                    v-for="(shop_obj, s) in mall_timings.generic_timings"
                    :key="s"
                    class="dropdown-item cursor-pointer"
                    @click="closeMenu"
                  >
                    <div class="Opening-Hours-title">
                      <v-icon v-if="shop_obj.category == 'MALL TIMINGS'"
                        >mdi-clock</v-icon
                      >
                      <v-icon v-if="shop_obj.category == 'RETAIL & KIOSK'"
                        >mdi-storefront</v-icon
                      >
                      <v-icon v-if="shop_obj.category == 'RESTAURANTS / CAFES'"
                        >mdi-food</v-icon
                      >
                      &nbsp;{{ shop_obj.category }}
                    </div>
                    <div class="d-flex" style="width: 100%; flex-flow: wrap">
                      <div
                        class="day-row"
                        v-for="(day, d) in shop_obj.timings"
                        :key="d"
                      >
                        <div>
                          <strong
                            >{{ day.from_day }}
                            <span v-if="day.to_day != day.from_day"
                              >- {{ day.to_day }}</span
                            ></strong
                          >
                        </div>
                        <div class="time-row">
                          {{ day.from_time }} {{ day.from_meridiem }}-{{
                            day.to_time
                          }}
                          {{ day.to_meridiem }}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <a v-else>
                  <div
                    class="dropdown-item cursor-pointer d-flex"
                    @click="closeMenu"
                  >
                    <div
                      v-for="(shop_obj, s) in mall_timings.generic_timings"
                      :key="s"
                      style="width: 80%; margin: 10px"
                    >
                      <img
                        @click="
                          openTimingsNewTab(envImagePath + shop_obj.image_path)
                        "
                        :src="envImagePath + shop_obj.image_path"
                      />
                    </div>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <!-- Nav Bar -->
    <div>
      <GlobalSearch v-if="$route.name == 'home'"></GlobalSearch>
    </div>
  </div>
</template>

<script>
import GlobalSearch from "./GlobalSearch.vue";
import TopNav from "./TopHeaderNav.vue";

const default_layout = "default";
export default {
  components: { GlobalSearch, TopNav },
  data() {
    return {
      currentTheme: localStorage.getItem("theme-type"),
      envImagePath: process.env.VUE_APP_IMAGE_PATH,
      today_hours: [],
      mall_timings: [],
      upcoming_events: [],
      header_links: [],
      page_builder_links: [],
      sel_lang: "",
      mall_logo: "",
      tab_value: "MALL TIMINGS",
      showPopup: false,
      tab: "option-1",
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    nextItems() {
      return this.notification_array.slice(
        this.currentIndex,
        this.currentIndex + this.itemsPerPage
      );
    },
  },
  mounted() {
    this.sel_lang = localStorage.getItem("pref_lang");
    this.fetchCategories();
    this.getTodayHours();
    this.fetchUpcomingEvents();
  },
  created() {
    this.emitter.on("app_image_update", () => {});
  },
  watch: {
    "$i18n.locale"(newLocale) {
      if (newLocale === "ar") {
        this.sel_lang = "ar";
      } else {
        ("");
        this.sel_lang = "en";
      }
    },
  },
  methods: {
    fetchCategories() {
      const params = {};
      if (this.$route.params.display || sessionStorage.getItem("ispreview")) {
        params.display = this.$route.params.display;
      }
      this.initval = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL_ADMIN + "fetch-lang-categories", {
          params: params,
        })
        .then((res) => {
          this.initval = false;
          this.header_links = res.data.categories;
          this.page_builder_links = res.data.pages;
          this.mall_logo = res.data.mall_logo;
        })
        .catch((err) => {
          console.log(" error" + err);
        });
    },
    closeMenu() {
      // Check if the navbar is collapsed (only applicable for mobile)
      const navbar = document.getElementById("navbarNavDropdown");
      if (navbar.classList.contains("show")) {
        // If it's collapsed, toggle it to close
        const toggler = document.querySelector(".navbar-toggler");
        toggler.click();
      }
    },
    openTimingsNewTab(image) {
      window.open(image, "_blank");
    },
    goToStoreLocator() {
      this.$router.push({ name: "store_locator" });
    },
    openTab(val) {
      // alert(val);
      this.tab_value = val;
    },
    openMallTimings() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    goToEventsDetails(slug) {
      this.$router.push({ name: "events-details", query: { slug: slug } });
    },
    getTodayHours() {
      this.$axios
        .get(process.env.VUE_APP_API_URL_ADMIN + "fetch-lang-mall-timings")
        .then((res) => {
          this.initval = false;
          // this.$toast.success(this.array_data);
          // this.today_hours = res.data.todaysTiming;
          this.mall_timings = res.data;
        })
        .catch((err) => {
          this.$toast.error(this.array_data);
          console.log(" error" + err);
        });
    },
    fetchUpcomingEvents() {
      this.$axios
        .get(process.env.VUE_APP_API_URL_ADMIN + "fetch-upcoming-events")
        .then((res) => {
          this.initval = false;
          // this.$toast.success(this.array_data);
          this.upcoming_events = res.data.events;
        })
        .catch((err) => {
          this.$toast.error(this.array_data);
          console.log(" error" + err);
        });
    },
    OpenSearchDialog() {
      this.dialog = true;
    },
    goToCategory(slug, parent, pagename) {
      //alert(pagename)
      if (pagename != null) {
        this.$router.push({
          name: pagename,
          query: { slug: slug, parent_cat: parent },
        });
      } else {
        return null;
      }
    },

    setUserLang(lang) {
      if (lang == "ar") {
        localStorage.setItem("pref_lang", "ar");
        this.sel_lang = "ar";
        this.$i18n.locale = "ar";
        let newRoute = {
          ...this.$route,
          params: {
            ...this.$route.params,
            lang: "ar",
          },
        };
        console.log(newRoute);
        this.$router.push(newRoute).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });
        setTimeout(function () {
          window.location.reload(1);
        }, 500);
      } else {
        this.sel_lang = "en";
        localStorage.setItem("pref_lang", "en");
        this.$i18n.locale = "en";

        let newRoute = {
          ...this.$route,
          params: {
            ...this.$route.params,
            lang: "en",
          },
        };
        console.log(newRoute);
        this.$router.push(newRoute).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });
      }
      setTimeout(function () {
        window.location.reload(1);
      }, 500);
    },
    goTo(slug) {
      this.$router.push({ name: "pagebuilder", query: { slug: slug } });
    },
    goToServices() {
      this.$router.push({ name: "services" });
    },

    // loadMore() {
    //   this.loader = true;
    //   this.displayedItems.push(...this.nextItems);
    //   this.currentIndex += this.itemsPerPage;
    //   if (!this.showLoadMoreButton) {
    //     console.log("All data loaded.");
    //   }
    //   this.loader = false;
    // },
  },
};
</script>
<style scoped>
.v-slide-group__content {
  margin-left: 11px !important;
}
strong {
  font-weight: 600;
  color: #ac8100;
}
.nav-link {
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}
.today-hr {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  border-bottom: 1px solid gray;
}
.day-row,
.time-row {
  width: 166px;
  padding: 10px 0px;
  color: #000;
}
.happening-title {
  width: 234px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  color: #b98c03;
}
.dropdown-item > div {
  font-size: 12px !important;
}
.f-14 {
  font-size: 14px;
}
.dropdwn-heading {
  margin: 2px 13px;
  text-transform: uppercase;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 7px;
  font-size: 12px;
}
.hours-block {
  padding: 0px 9px;
  /* border: 2px solid #474747; */
  /* background: #000; */
}
.hours-block:hover {
  background: #ffbf00;
}
.menu-name {
  font-size: 16px !important;
}
.sub-menu-name {
  font-size: 14px !important;
}
.nav-item > a,
.hours-block > a {
  color: #000 !important;
}
.fa {
  font-size: medium !important;
  margin-right: 2px !important;
}
/* .hours-block:hover a{color: #fff;} */
/* .hours-block>a{color:#fff !important} */
/* .navbar-expand-lg .navbar-nav .dropdown-menu{left: 2px;} */
</style>

